import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src020753457/src/braceta-com-website/gatsby-theme-terminal/src/layouts/PageLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This is a dummy hidden page containing required frontmatter. It's filtered out but matching the navigationLabel DefaultLayout => MdxRoutes don/'t remove it`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      